:root {
  --white: white;
  --black: black;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.body {
  background-color: #f7f8f8;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.div-tormo-logo {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.tormo {
  width: 50px;
  margin-bottom: 10px;
}

.div-white {
  width: 45px;
  height: 45px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  display: flex;
  box-shadow: 2px 2px 11px -3px #979797;
}

.flex-block {
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.div-wrapper {
  width: 75%;
  height: auto;
  max-height: none;
  max-width: 75%;
  min-height: auto;
  min-width: 75%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-flow: column;
  grid-template-rows: 400px auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  align-items: stretch;
  justify-items: stretch;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  min-height: 100vh; /* Ensures that the div stretches to at least the full height of the viewport */
}

.div-detail {
  height: 90%;
  background-color: #fff;
  border: 1px #000;
  border-radius: 13px 13px 0 0;
  box-shadow: 2px 2px 11px -3px #979797;
}

.div-detail.hey {
  position: absolute;
}

.div-detail.nft {
  height: 80%;
  border-radius: 0 0 13px 13px;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 30px;
  display: flex;
}

.div-detail.gallery {
  justify-content: space-between;
  display: flex;
}

.hero-subscribe-right {
  background-color: rgba(250, 245, 245, 0);
  border-bottom: 1px #e4ebf3;
  padding: 40px 30px;
  position: relative;
}

.container {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.hero-wrapper {
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero-split {
  max-width: 46%;
  width: 46%;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.hero-split.image {
  width: 100%;
  height: 100%;
}

.shadow-two {
  border-radius: 13px;
  box-shadow: 0 4px 24px rgba(150, 163, 181, .08);
}

.margin-bottom-24px {
  height: 31%;
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 27px;
}

.hero-form {
  margin-bottom: 12px;
}

.hero-form-container {
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero-form-input {
  width: 240px;
  height: auto;
  color: #1a1b1f;
  border: 1px solid #d1d6db;
  margin-bottom: 0;
  margin-right: 9px;
  padding: 5px 16px;
  font-size: 14px;
  line-height: 20px;
  transition: all .2s;
}

.hero-form-input:hover, .hero-form-input:focus {
  border-color: #76879d;
}

.hero-form-input::-ms-input-placeholder {
  color: rgba(26, 27, 31, .8);
}

.hero-form-input::placeholder {
  color: rgba(26, 27, 31, .8);
}

.button-primary {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
}

.button-primary:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary:active {
  background-color: #43464d;
}

.text-link-arrow {
  color: #1a1b1f;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.arrow-embed {
  margin-left: 2px;
  display: flex;
}

.heading {
  height: 24%;
  margin-top: 10px;
  font-size: 24px;
  line-height: 34px;
}

.form {
  display: flex;
}

.text-field {
  color: #000;
  border: 2px solid #e84915;
  border-radius: 16px;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 0;
  font-size: 10px;
}

.submit-button-ye {
  width: 100px;
  border-radius: 16px;
  background-color: #e84915;
}

.text-block {
  font-size: 25px;
  line-height: 30px;
  transform: rotate(-90deg);
}

.div-block {
  padding-top: 60px;
}

.section-gallery {
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.div-nft-container {
  width: 0%;
}

.container-nft-gallery {
  width: 95%;
  height: 100%;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.div-nft-box {
  width: 100%;
  height: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template: "." 1fr
                 "." 1fr
                 / 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  justify-content: stretch;
  align-items: center;
  justify-items: center;
  display: grid;
}

.div-block-2 {
  background-color: #5a5a5a;
}

.image-2 {
  max-width: none;
}

.features-list {
  border-bottom: 1px solid #e4ebf3;
  padding: 80px 30px;
  position: relative;
}

.container-nft {
  width: 100%;
  height: 100%;
  max-width: 940px;
  background-image: url('./images/Rectangle4.png');
  background-position: 50%;
  background-size: 89px;
  margin-left: auto;
  margin-right: auto;
}

.features-wrapper-two {
  width: 100%;
  height: 100%;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.features-left {
  max-width: 40%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.features-paragraph {
  margin-bottom: 24px;
}

.text-link-arrow-2 {
  color: #1a1b1f;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.arrow-embed-2 {
  margin-left: 2px;
  display: flex;
}

.features-right {
  max-width: 46%;
  flex-flow: row;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.features-block-two {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.features-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-right: 0;
}

.nft-images-here {
  height: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  display: grid;
}

.quick-stack {
  padding-top: 0;
}

.quick-stack-2 {
  height: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 0;
}

.nft-image {
  width: 100%;
  height: 100%;
}

.cell {
  background-image: url('./images/Rectangle2.png');
  background-position: 50%;
  background-size: cover;
}

.cell-2 {
  background-image: url('./images/Rectangle3.png');
  background-position: 50%;
  background-size: auto;
}

.cell-3 {
  background-image: url('./images/Rectangle4.png');
  background-position: 50%;
  background-size: cover;
}

.team-circles {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #e4ebf3;
  padding: 0;
  position: relative;
}

.centered-heading {
  text-align: center;
  margin-bottom: 16px;
}

.centered-subheading {
  max-width: 530px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.team-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  margin-top: 0;
  display: grid;
}

.team-card {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  font-size: 14px;
  line-height: 22px;
  display: flex;
}

.team-card.no {
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.team-member-image {
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
  border-radius: 0%;
  margin-bottom: 0;
}

.team-member-image.no {
  width: 50%;
  height: 50%;
  filter: grayscale();
}

.team-member-name {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.team-member-position {
  margin-bottom: 24px;
}

.div-wrapper-logo-address {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wallet {
  width: 50%;
  height: 50%;
}

.login {
  justify-content: center;
  display: flex;
}

.header-login {
  padding-top: 20px;
  padding-left: 30px;
  display: block;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.image {
  width: 74px;
  height: 65px;
}

.div-login {
  width: 50%;
  background-color: #f7f8f8;
  flex-direction: column;
  align-items: center;
  display: flex;
  min-height: 100vh; /* Ensures that the div stretches to at least the full height of the viewport */
}

.subtitle-login {
  text-align: center;
  margin-top: 102px;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
}

.subtitle-login.sub {
  margin-top: 50px;
}

.button {
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  text-align: center;
  background-color: #e84915;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  display: flex;
}

.button.vvv {
  width: 50%;
  height: 50px;
  max-height: 50px;
  max-width: 50%;
  min-height: 50px;
  min-width: 50%;
  border-radius: 32px;
  margin-left: 0;
  font-style: normal;
  font-weight: 700;
}

.form-text-input-email {
  width: 50%;
  height: 50px;
  min-width: 50px;
  color: #000;
  border: 2px solid #e84915;
  border-radius: 32px;
  margin-bottom: 0;
  margin-right: 0;
  padding-left: 40px;
  font-weight: 700;
}

.form-text-input-email.email {
  max-width: 10%;
  min-height: 50%;
  min-width: 50%;
}

.div-login-main {
  width: 95%;
  height: 85%;
  background-color: #fff;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
  box-shadow: 2px 2px 11px -3px #979797;
}

.form-email-take {
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
}

.form-block {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-top: 74px;
  margin-bottom: 0;
  display: flex;
}

.form-block._4digits {
  margin-top: 10px;
}

.form-block.votething {
  margin-top: 50px;
}

.heading-title {
  color: #202120;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 0;
  font-size: 50px;
  font-weight: 400;
  line-height: 64px;
}

.heading-title.heading-title-login {
  margin-top: 100px;
}

.otp {
  justify-content: center;
  display: flex;
}

.div-password {
  width: 50%;
  background-color: #f7f8f8;
  flex-direction: column;
  align-items: center;
  display: flex;
  min-height: 100vh; /* Ensures that the div stretches to at least the full height of the viewport */
}

.text-span {
  color: #e84915;
  text-decoration: underline;
}

.text-block-2 {
  text-align: center;
  margin-top: 102px;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
}

.text-block-2.votething {
  margin-top: 50px;
}

.text-field-2 {
  width: 50%;
  height: 50px;
  min-width: 50px;
  color: #000;
  border: 2px solid #e84915;
  border-radius: 32px;
  margin-bottom: 0;
  margin-right: 0;
  padding-left: 40px;
  font-weight: 700;
}

.text-field-2.email {
  max-width: 10%;
  min-height: 50%;
  min-width: 50%;
}

.text-field-2.email.otp {
  width: 55px;
  max-width: 55px;
  min-width: 55px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 12px;
  font-size: 15px;
}

.div-password-inside {
  width: 95%;
  height: 85%;
  background-color: #fff;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
  box-shadow: 2px 2px 11px -3px #979797;
}

.form-2 {
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
}

.heading-2 {
  color: #202120;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 0;
  font-size: 50px;
  font-weight: 400;
  line-height: 64px;
}

.heading-2.votething {
  margin-top: 100px;
}

@media screen and (min-width: 1440px) {
  .team-member-image {
    width: 100%;
    height: 100%;
    max-height: none;
  }

  .header-login {
    padding-top: 5px;
    padding-left: 10px;
  }

  .div-login-main {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }

  .heading-title {
    margin-top: 185px;
  }

  .div-password-inside {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }

  .heading-2 {
    margin-top: 185px;
  }
}

@media screen and (max-width: 991px) {
  .body {
    flex-flow: row;
    justify-content: flex-end;
    align-items: stretch;
  }

  .div-tormo-logo {
    flex-flow: row;
  }

  .tormo {
    margin-bottom: 0;
  }

  .div-white {
    width: 45px;
    height: 45px;
    margin-left: 10px;
  }

  .flex-block {
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
  }

  .div-wrapper {
    width: 95%;
    max-width: 95%;
    min-width: 95%;
  }

  .div-detail {
    border-top-left-radius: 13px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 0;
  }

  .div-detail.nft {
    border-top-left-radius: 13px;
    border-bottom-right-radius: 0;
    margin-top: 70px;
    margin-bottom: 30px;
  }

  .hero-subscribe-right {
    max-width: 100%;
  }

  .container {
    max-width: 728px;
  }

  .hero-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 0;
  }

  .hero-split {
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 0;
  }

  .hero-split.image {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
  }

  .shadow-two {
    width: 45%;
  }

  .hero-form-container {
    justify-content: space-between;
  }

  .hero-form-input {
    width: 75%;
    margin-right: 0;
  }

  .button-primary {
    width: auto;
    text-align: center;
  }

  .text-block {
    font-size: 20px;
  }

  .container-nft-gallery {
    padding-left: 0;
    padding-right: 0;
  }

  .div-nft-box {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .container-nft {
    max-width: 728px;
  }

  .features-wrapper-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .features-left {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .features-right {
    max-width: 100%;
  }

  .team-grid {
    grid-column-gap: 0px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .team-member-image {
    width: 100%;
    height: 100%;
  }

  .header-login {
    padding-top: 10px;
    padding-left: 40px;
  }

  .div-login {
    width: 95%;
  }

  .form-email-take {
    padding-left: 50px;
    padding-right: 50px;
  }

  .div-password {
    width: 95%;
  }

  .form-2 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 767px) {
  .hero-subscribe-right {
    padding: 40px 15px;
  }

  .hero-wrapper {
    margin-bottom: 0;
  }

  .hero-form-input {
    width: 65%;
  }

  .text-block {
    font-size: 15px;
    line-height: 25px;
  }

  .container-nft-gallery {
    padding-left: 0;
    padding-right: 0;
  }

  .div-nft-box {
    width: 100%;
    grid-template-rows: .5fr .5fr;
    grid-template-columns: .5fr .5fr .5fr .5fr;
    grid-auto-rows: .5fr;
    grid-auto-columns: .5fr;
  }

  .features-list {
    padding: 60px 15px;
  }

  .team-circles {
    padding: 0 0 0 15px;
  }

  .team-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .header-login {
    padding-top: 10px;
    padding-left: 35px;
  }

  .div-login {
    width: 95%;
  }

  .form-email-take {
    padding-left: 10px;
    padding-right: 10px;
  }

  .div-password {
    width: 95%;
  }

  .form-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 479px) {
  .div-detail.nft {
    display: flex;
  }

  .hero-subscribe-right {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .container {
    max-width: none;
  }

  .hero-wrapper {
    margin-bottom: 0;
  }

  .shadow-two {
    width: 55%;
  }

  .margin-bottom-24px {
    font-size: 9px;
    line-height: 18px;
  }

  .hero-form {
    align-self: stretch;
    margin-bottom: 15px;
  }

  .hero-form-container {
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .hero-form-input {
    width: 55%;
    height: 48px;
    margin-bottom: 10px;
  }

  .button-primary {
    padding: 5px 10px;
    font-size: 5px;
  }

  .heading {
    font-size: 14px;
    line-height: 25px;
  }

  .text-field {
    padding-left: 10px;
    font-size: 8px;
  }

  .submit-button-ye {
    font-size: 10px;
  }

  .text-block {
    font-size: 12px;
    line-height: 24px;
  }

  .container-nft-gallery {
    padding-left: 0;
    padding-right: 0;
  }

  .div-nft-box {
    grid-template-rows: .5fr .5fr;
    grid-template-columns: .5fr .5fr;
    grid-auto-rows: .5fr;
    grid-auto-columns: .5fr;
    grid-auto-flow: row dense;
    align-items: center;
    justify-items: center;
  }

  .container-nft {
    max-width: none;
  }

  .features-right {
    margin-bottom: -30px;
  }

  .features-block-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .features-image {
    margin-bottom: 10px;
  }

  .centered-heading {
    margin-bottom: 24px;
  }

  .team-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .header-login {
    padding-top: 5px;
    padding-left: 15px;
  }

  .image {
    width: 49.3px;
    height: 43.3px;
  }

  .div-login {
    width: 100%;
  }

  .subtitle-login {
    margin-top: 100px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 24px;
  }

  .button {
    margin-left: 5px;
    margin-right: 5px;
  }

  .button.vvv {
    width: 90%;
    max-width: 90%;
    min-width: 90%;
    margin-right: 0;
  }

  .form-text-input-email {
    width: 90%;
    margin-left: 5px;
    padding-left: 25px;
  }

  .form-text-input-email.email {
    max-width: 90%;
    min-width: 90%;
    margin-left: 0;
  }

  .form-email-take {
    padding-left: 0;
    padding-right: 0;
  }

  .form-block.votething {
    align-items: stretch;
  }

  .heading-title {
    font-size: 35px;
    line-height: 35px;
  }

  .div-password {
    width: 100%;
  }

  .text-block-2 {
    margin-top: 100px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 24px;
  }

  .text-field-2 {
    width: 90%;
    margin-left: 5px;
    padding-left: 25px;
  }

  .text-field-2.email {
    max-width: 90%;
    min-width: 90%;
    margin-left: 0;
  }

  .form-2 {
    padding-left: 0;
    padding-right: 0;
  }

  .heading-2 {
    font-size: 35px;
    line-height: 35px;
  }

  .div-block {
    padding-top: 40px;
  }
}

#w-node-_0555cecf-42c5-bbfe-3c5d-7c605b0a1fcb-d2715ff0 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2f8db565-fc4d-b705-8b97-fdd111aa249c-d2715ff0, #w-node-a947269a-47fb-4b28-071c-bd233ef9fb74-d2715ff0, #w-node-ef1c1beb-0c23-3003-5468-cc1dcc202920-d2715ff0, #w-node-_949c1fc7-ab6d-22e9-72c7-3882ae0c5edd-d2715ff0, #w-node-e4b97d41-ebda-f86c-8835-263d10de0eaf-d2715ff0, #w-node-_2b5ff6f7-9fbc-446c-cb88-553e1287cb98-d2715ff0, #w-node-_17bd587b-967d-74d2-6f82-5aeb3dd3de0a-d2715ff0, #w-node-_60202890-43a2-a2e6-4b7f-a4b903516d97-d2715ff0, #w-node-c8fd01e4-2b48-2332-8cb9-1aa8cec95847-d2715ff0, #w-node-_3dbd53e6-2775-12e2-23d7-55511b425e7b-d2715ff0 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: center;
}
