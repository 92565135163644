.modal-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.mdoal-backgound {
  background-color: rgba(0, 0, 0, .6);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.modal-card {
  width: 50%;
  height: auto;
  background-color: #fff;
  border-radius: 13px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.heading-popup {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
}

.form-popup {
  width: 100%;
  flex-flow: column;
  display: flex;
}

.text-block-popup {
  margin-bottom: 20px;
}

/*
.text-field-popup {
  color: #000;
  text-align: center;
  border-radius: 13px;
  border-width: 2px;
  border-color: #e84915;
}*/

.text-field-popup {
  color: #000;
  text-align: center;
  border-radius: 13px;
  border: 2px solid #e84915; /* Updated this line */
  padding: 10px; /* Add some padding for visual comfort */
  margin-bottom: 20px; /* Ensure some space below the input */
  width: calc(100% - 24px); /* Adjust width to account for padding and ensure it stays within the parent container */
  box-sizing: border-box; /* Ensures padding does not add to the overall width */
  width: 100%;
}


.submit-button-popup {
  background-color: #e84915;
  border-radius: 13px;
}

@media screen and (max-width: 991px) {
  .modal-card {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .modal-card {
    width: 90%;
  }
}

@media screen and (max-width: 479px) {
  .modal-card {
    width: 80%;
  }
}
